import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import WhoAreWe from "../../components/who-are-we"
import SEO from "../../components/seo"

class Cosmos extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-cosmos inuit-box inuit-box--flush">
            <h2 className="hero__message hero__message--bigger hero__message--center">
                </h2>
            </div>

            <article className="article project inuit-u-p0">

                <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="http://bosch.com/" title="Programme EXTRA!" target="_blank">
                                <img src="/img/clients/cosmos.jpg" alt="" className="project__logo" />
                            </a>
                        </p>

                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">COSMOS ELECTRO</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link" href="https://cosmos.utopie.ma/" title="JEU CONCOUR" target="_blank">JEU
                                    CONCOURS</a>
                            </span>
                            <span className="project__detail">Since 2017</span>
                        </p>

                        <ul className="article__share-list">
                            <li className="article__share-item">
                                <a href="#"
                                    className="article__share-link article__share-link--facebook" target="blank" title="Share on Facebook"></a>
                            </li>
                            <li className="article__share-item">
                                <a href="#"
                                    className="article__share-link article__share-link--twitter" target="blank" title="Share on Twitter"></a>
                            </li>
                            <li className="article__share-item">
                                <a href="#"
                                    className="article__share-link article__share-link--linkedin" target="blank" title="Share on Linkedin"></a>
                            </li>

                            <li className="article__share-item">
                                <a href="#" onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"
                                    className="article__share-link article__share-link--gplus" target="blank" title="Share on Google Plus"></a>
                            </li>
                        </ul>
                    </aside>

                    <h1 className="article__heading">LA ROUE DE LA FORTUNE.</h1>
                    <p className="article__para">
                        Un Bon Cadeau est envoyé par E-mail au gagnant.
                        Ce "Bon Cadeau" est automatiquement valable une seule fois dans le magasin COSMOS au choix. <br />

                    </p>
                    <p className="article__para">
                        <span className="project__detail project__detail--small">Conception, UX/UI, Webdesign, Digital, Probabilités,
                            Devloppement Back et Front End</span>
                    </p>
                </div>

                <p className="article__belt inuit-u-mt0">
                    <img className="article__belt-image" src="/img/projects/cosmos/cosmos-steps.jpg" alt="mobile-apps" />
                </p>

                <div className="article__body">
                    <h2 className="article__heading">Tester le jeu sur la recette :)</h2>
                    <p className="article__para">
                        <a href="https://cosmos.utopie.ma" target="_blank"> <img src="/img/projects/cosmos/cosmos-play.jpg" />
                        </a>
                        <br /><br />
                        Vous avez eu 5% ou 6%? Ce n'est pas que vous n'avez pas de chance ni par hasard :D
                        <br /><br />
                    </p>
                </div>
                
            </article>
            <WhoAreWe />
        </Layout>
    
  )

}

export default Cosmos
